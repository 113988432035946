require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/20176ac174bf8635aebc4c9301dad4f5/code.js', () => { 

BackendlessUI.Functions.Custom['fn_20176ac174bf8635aebc4c9301dad4f5'] = async function fn_20176ac174bf8635aebc4c9301dad4f5() {
var full_signup_list, signups, j;

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  if (3 % 2 == 0) {
    full_signup_list = [];
    var j_list = (await BackendlessUI.Functions.Custom['fn_fa9eef585352695210996477e422fbce']());
    for (var j_index in j_list) {
      j = j_list[j_index];
      signups = (await Backendless.Request['get']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})('https://api.securevan.com/v4/signups?EventId=:eventId')).set(({ [`auth_name`]: 'NGP31.000001.456',[`api_key`]: '47f4b0e7-d681-4377-de0f-d1f297b455ef|1',[`eventId`]: 'EventId' })).setEncoding('utf8').send());
      addItemToList(full_signup_list, signups);
    }
    await Backendless.Data.of('Event_signups').bulkUpsert( full_signup_list );
  }

return 'nothing'
}

})
define('./functions/fa9eef585352695210996477e422fbce/code.js', () => { 

BackendlessUI.Functions.Custom['fn_fa9eef585352695210996477e422fbce'] = async function fn_fa9eef585352695210996477e422fbce() {
var Event_List;


  Event_List = (await Backendless.Request.get(`${Backendless.appPath}/services/EventLIst2/EventList`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...({ [`accept`]: 'application/json' }), ...{ 'user-token': 'Basic TkdQMzEuMDAwMDAxLjQ1NjoxZTFhMjkzMC1lM2M4LWRiZTMtZGU4Yi0yYWU1Mjk3MTAwNTJ8MQ==' }})).send());
  await Backendless.Data.of('Event_list').bulkUpsert( Event_List );

return Event_List
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/CheckIn/components/1cd27d3b89bae7bfdd0f4773e27d9948/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selectedEventId, eventSignupsData;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function do_something2() {
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1cd27d3b89bae7bfdd0f4773e27d9948')), 'background-color')) != 'green') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1cd27d3b89bae7bfdd0f4773e27d9948'))['background-color'] = 'green';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1cd27d3b89bae7bfdd0f4773e27d9948'))['background-color'] = 'white';
  }
}

/**
 * Describe this function...
 */
async function do_something() {
  eventSignupsData['VanId'] = (getObjectProperty((getObjectProperty(eventSignupsData, 'person')), 'vanId'));
  eventSignupsData['FirstName'] = (getObjectProperty((getObjectProperty(eventSignupsData, 'person')), 'firstName'));
  eventSignupsData['LastName'] = (getObjectProperty((getObjectProperty(eventSignupsData, 'person')), 'lastName'));
  console.log(eventSignupsData);
}


  ___arguments.context.pageData['selectedEvent'] = ___arguments.context.dataModel;
  selectedEventId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'selectedEvent')), 'eventID'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('6f6a5edf7bf8c6af5721f5eb95a4c2cb', (await Backendless.Request.get(`${Backendless.appPath}/services/EventLIst2/EventSignups`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'EventId': selectedEventId }).send()));

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1cd27d3b89bae7bfdd0f4773e27d9948')), 'background-color')) != 'green') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1cd27d3b89bae7bfdd0f4773e27d9948'))['background-color'] = '#cccccc';
  }

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1cd27d3b89bae7bfdd0f4773e27d9948')), 'background-color')) != 'green') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1cd27d3b89bae7bfdd0f4773e27d9948'))['background-color'] = 'white';
  }

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/CheckIn/components/cf3bbb3c219869680aab2f82d775ff6a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('36e78eef962f664280f4641eddf448fd'), 'person')), 'firstName')),' ',(getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('36e78eef962f664280f4641eddf448fd'), 'person')), 'lastName'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/CheckIn/components/36e78eef962f664280f4641eddf448fd/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('36e78eef962f664280f4641eddf448fd')), 'background-color')) != 'green') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('36e78eef962f664280f4641eddf448fd'))['background-color'] = '#cccccc';
  }

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('36e78eef962f664280f4641eddf448fd')), 'background-color')) != 'green') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('36e78eef962f664280f4641eddf448fd'))['background-color'] = 'white';
  }

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selectedvanId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function do_something() {
  return getObjectProperty(___arguments.context.pageData, 'selectedSignups')
}


  ___arguments.context.pageData['selectedSignups'] = ___arguments.context.dataModel;
  selectedvanId = (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'selectedSignups')), 'person')), 'vanId'));
  await Backendless.Request.get(`${Backendless.appPath}/services/CheckIn/MarkAsCompleted`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'eventSignupId': (getObjectProperty(___arguments.context.dataModel, 'eventSignupId')),'vanId': JSON.stringify((getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'person')), 'vanId'))),'eventId': (getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'event')), 'eventId')),'eventShiftId': (getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'shift')), 'eventShiftId')),'roleId': (getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'role')), 'roleId')),'statusId': 2,'locationId': (getObjectProperty((getObjectProperty(___arguments.context.dataModel, 'location')), 'locationId')) }).send();
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('36e78eef962f664280f4641eddf448fd')), 'background-color')) != 'green') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('36e78eef962f664280f4641eddf448fd'))['background-color'] = 'green';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('36e78eef962f664280f4641eddf448fd'))['background-color'] = 'white';
  }

  },
  /* handler:onClick */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
      return ___arguments.context.dataModel

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/CheckIn/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var eventListData, eventData, end_Date, eventObjectID, eventObjectIDList, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function do_something2() {
  end_Date = eventData.indexOf('startDate') + 1;
  console.log(end_Date);
  console.log(getObjectProperty(eventListData, 'startDate'));
}

/**
 * Describe this function...
 */
async function do_something() {
  eventObjectIDList = (await Backendless.Request.get(`${Backendless.appPath}/services/EventLIst2/EventList`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  var eventObjectID_list = (await Backendless.Data.of('EventListAH').find(Backendless.DataQueryBuilder.create().setPageSize(10)));
  for (var eventObjectID_index in eventObjectID_list) {
    eventObjectID = eventObjectID_list[eventObjectID_index];
    console.log(eventObjectID);
    Object.assign((await Backendless.Data.of('EventListAH').findById(eventObjectID, )), ({ [`event_StartDate`]: (new Date()) }));
  }
}


  eventListData = (await Backendless.Request.get(`${Backendless.appPath}/services/EventLIst2/EventList`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  eventData = eventListData[0];
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('9896ad9f9c3efe795a377e5aa86febbb', (await Backendless.Data.of('EventListAH').find(Backendless.DataQueryBuilder.create().setSortBy('eventStartDateTime').setPageSize(100))));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/CheckIn/components/b023a666499816889a17fbb8b6d52e64/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('36e78eef962f664280f4641eddf448fd'), 'status')), 'name'))

  },
  /* handler:onContentAssignment */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentByUid(componentUid) })('4f3b1408bf71d73fc66f403658e373b1'))['content'] = 'Checked In';

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CheckIn/components/65df791f2dbb27321e94d58660e7d206/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
    var test2, createdPerson;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function do_something() {
}

/**
 * Describe this function...
 */
async function do_something2() {
}


  if ((await Backendless.Request.get(`${Backendless.appPath}/services/EventLIst2/SearchPeople`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'firstName': JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'firstName'))),'lastName': JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'lastName'))),'email': JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'email'))) }).send()) == 0) {
    test2 = (await Backendless.Request.get(`${Backendless.appPath}/services/CheckIn/FindOrCreatePerson`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'firstName': JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'firstName'))),'lastName': JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'lastName'))),'email': JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'email'))) }).send());
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('8f3f63f6ed2370e9b9c1d5c8507e0027', (await Backendless.Request.get(`${Backendless.appPath}/services/CheckIn/SearchVanId`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'vanId': JSON.stringify((getObjectProperty(test2, 'vanId'))) }).send()));
  } else {
    (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('8f3f63f6ed2370e9b9c1d5c8507e0027', (await Backendless.Request.get(`${Backendless.appPath}/services/EventLIst2/SearchPeople`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'firstName': JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'firstName'))),'lastName': JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'lastName'))),'email': JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'email'))) }).send()));
  }

  },
  /* handler:onSubmit */
  /* content */
}))

define('./pages/CheckIn/components/ac446dec99dad717d19ef4a4dd6dfb05/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function do_something() {
}


  console.log(___arguments.context.getComponentDataStoreByUid('feb7917a91237c3753d58d17e6b348c1'));

  return ([(getObjectProperty(___arguments.context.getComponentDataStoreByUid('feb7917a91237c3753d58d17e6b348c1'), 'firstName')),' ',(getObjectProperty(___arguments.context.getComponentDataStoreByUid('feb7917a91237c3753d58d17e6b348c1'), 'lastName'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/CheckIn/components/feb7917a91237c3753d58d17e6b348c1/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function do_something() {
  console.log(getObjectProperty((getObjectProperty(___arguments.context.pageData, 'selectedEvent')), 'eventID'));
}


  await Backendless.Request.get(`${Backendless.appPath}/services/CheckIn/CreateWalkIn`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'eventId': (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'selectedEvent')), 'eventID')),'vanId': JSON.stringify((getObjectProperty(___arguments.context.dataModel, 'vanId'))),'statusId': 15 }).send();

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/CreateNametags/components/1cd27d3b89bae7bfdd0f4773e27d9948/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selectedEventId, eventSignupsData, item;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function sortBy(list, order) {
  return list.sort(function(elementA, elementB) {
     const a = elementA.value
     const b = elementB.value
     let result = 0

     if (a > b) result = 1
     if (a < b) result = -1

     return result * order
   })
}

async function asyncListSort(sourceList, order, callback) {
 const list = await Promise.all(sourceList.map(async source => ({
   source,
   value: await callback(source),
 })));

 sortBy(list, order);

 return list.map(item => item.source)
}

/**
 * Describe this function...
 */
async function do_something2() {
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1cd27d3b89bae7bfdd0f4773e27d9948')), 'background-color')) != 'green') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1cd27d3b89bae7bfdd0f4773e27d9948'))['background-color'] = 'green';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1cd27d3b89bae7bfdd0f4773e27d9948'))['background-color'] = 'white';
  }
}

/**
 * Describe this function...
 */
async function do_something() {
  eventSignupsData['VanId'] = (getObjectProperty((getObjectProperty(eventSignupsData, 'person')), 'vanId'));
  eventSignupsData['FirstName'] = (getObjectProperty((getObjectProperty(eventSignupsData, 'person')), 'firstName'));
  eventSignupsData['LastName'] = (getObjectProperty((getObjectProperty(eventSignupsData, 'person')), 'lastName'));
  console.log(eventSignupsData);
}


  ___arguments.context.pageData['selectedEvent'] = ___arguments.context.dataModel;
  selectedEventId = (getObjectProperty((getObjectProperty(___arguments.context.pageData, 'selectedEvent')), 'eventID'));
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('6f6a5edf7bf8c6af5721f5eb95a4c2cb', (await asyncListSort((await Backendless.Request.get(`${Backendless.appPath}/services/EventLIst2/EventSignups`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'EventId': selectedEventId }).send()), 1, async (item) => {


   return item;
  })));

  },
  /* handler:onClick */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1cd27d3b89bae7bfdd0f4773e27d9948')), 'background-color')) != 'green') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1cd27d3b89bae7bfdd0f4773e27d9948'))['background-color'] = '#cccccc';
  }

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1cd27d3b89bae7bfdd0f4773e27d9948')), 'background-color')) != 'green') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('1cd27d3b89bae7bfdd0f4773e27d9948'))['background-color'] = 'white';
  }

  },
  /* handler:onMouseOut */
  /* content */
}))

define('./pages/CreateNametags/components/cf3bbb3c219869680aab2f82d775ff6a/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('36e78eef962f664280f4641eddf448fd'), 'person')), 'firstName')),' ',(getObjectProperty((getObjectProperty(___arguments.context.getComponentDataStoreByUid('36e78eef962f664280f4641eddf448fd'), 'person')), 'lastName'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/CreateNametags/components/36e78eef962f664280f4641eddf448fd/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('36e78eef962f664280f4641eddf448fd')), 'background-color')) != 'green') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('36e78eef962f664280f4641eddf448fd'))['background-color'] = '#cccccc';
  }

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('36e78eef962f664280f4641eddf448fd')), 'background-color')) != 'green') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('36e78eef962f664280f4641eddf448fd'))['background-color'] = 'white';
  }

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var selectedvanId;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  ___arguments.context.pageData['selectedSignups'] = ___arguments.context.dataModel;
  selectedvanId = (getObjectProperty((getObjectProperty((getObjectProperty(___arguments.context.pageData, 'selectedSignups')), 'person')), 'vanId'));
  await Backendless.Request.get(`${Backendless.appPath}/services/EventLIst2/Alex_test`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'vanId': selectedvanId }).send();
  if ((getObjectProperty(((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('36e78eef962f664280f4641eddf448fd')), 'background-color')) != 'green') {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('36e78eef962f664280f4641eddf448fd'))['background-color'] = 'green';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('36e78eef962f664280f4641eddf448fd'))['background-color'] = 'white';
  }

  },
  /* handler:onClick */
  /* handler:onDynamicItemsAssignment */
  ['onDynamicItemsAssignment'](___arguments) {
      return ___arguments.context.dataModel

  },
  /* handler:onDynamicItemsAssignment */
  /* content */
}))

define('./pages/CreateNametags/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var eventListData, eventData, end_Date, eventObjectID, eventObjectIDList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function do_something2() {
  end_Date = eventData.indexOf('startDate') + 1;
  console.log(end_Date);
  console.log(getObjectProperty(eventListData, 'startDate'));
}

/**
 * Describe this function...
 */
async function do_something() {
  eventObjectIDList = (await Backendless.Request.get(`${Backendless.appPath}/services/EventLIst2/EventList`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  var eventObjectID_list = (await Backendless.Data.of('EventListAH').find(Backendless.DataQueryBuilder.create().setPageSize(10)));
  for (var eventObjectID_index in eventObjectID_list) {
    eventObjectID = eventObjectID_list[eventObjectID_index];
    console.log(eventObjectID);
    Object.assign((await Backendless.Data.of('EventListAH').findById(eventObjectID, )), ({ [`event_StartDate`]: (new Date()) }));
  }
}


  eventListData = (await Backendless.Request.get(`${Backendless.appPath}/services/EventLIst2/EventList`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  eventData = eventListData[0];
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('9896ad9f9c3efe795a377e5aa86febbb', (await Backendless.Data.of('EventListAH').find(Backendless.DataQueryBuilder.create().setSortBy('eventStartDateTime').setPageSize(100))));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/CreateNametags/components/8b58e99bfe9999534bdddecc9ade8251/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var datenow;

/**
 * Describe this function...
 */
async function do_something2() {
}


  datenow = ((new Date()).toISOString());
  ___arguments.context.pageData['https://loyalfire-us.backendless.app/api/files/Generated Name Tags'] = (await Backendless.Request.post(`${Backendless.appPath}/services/CraftMyPDF/generate`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify(({ [`templateId`]: '98f77b2301ac1c9a',[`data`]: ({ [`items`]: (await Backendless.Request.get(`${Backendless.appPath}/services/EventLIst2/FindObjectsWithPaging`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'tableName': JSON.stringify('printList') }).send()) }),[`outputFile`]: String('Nametags_') + String(datenow) }))));
  await new Promise(r => setTimeout(r, 1000 || 0));
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((Object.keys(({ [`datenow`]: datenow })).reduce((template, key) => template = template.replace(':' + key, ({ [`datenow`]: datenow })[key]), 'https://apps.meckdems.org/api/files/https:/loyalfire-us.backendless.app/api/files/Generated Name Tags/Nametags_:datenow.pdf')), true, undefined);
  await Backendless.Data.of('PrintList').bulkDelete( 'ObjectID is not null' );

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/EventListWebsite/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    var eventListData, eventData, end_Date, eventObjectID, eventObjectIDList;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function do_something2() {
  end_Date = eventData.indexOf('startDate') + 1;
  console.log(end_Date);
  console.log(getObjectProperty(eventListData, 'startDate'));
}

/**
 * Describe this function...
 */
async function do_something() {
  eventObjectIDList = (await Backendless.Request.get(`${Backendless.appPath}/services/EventLIst2/EventList`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  var eventObjectID_list = (await Backendless.Data.of('EventListAH').find(Backendless.DataQueryBuilder.create().setPageSize(10)));
  for (var eventObjectID_index in eventObjectID_list) {
    eventObjectID = eventObjectID_list[eventObjectID_index];
    console.log(eventObjectID);
    Object.assign((await Backendless.Data.of('EventListAH').findById(eventObjectID, )), ({ [`event_StartDate`]: (new Date()) }));
  }
}


  eventListData = (await Backendless.Request.get(`${Backendless.appPath}/services/testService/AlexTest2`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send());
  eventData = eventListData[0];
  (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('1575e4bfa4c2a818db4c1872a599be1c', (await Backendless.Data.of('EventListAH').find(Backendless.DataQueryBuilder.create().setSortBy('eventStartDateTime').setPageSize(100))));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/EventListWebsite/components/9cae4e9b75e4b4e1c66d6f9757e2012d/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

/**
 * Describe this function...
 */
async function do_something() {
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((Object.keys(({ [`EventTitle`]: undefined })).reduce((template, key) => template = template.replace(':' + key, ({ [`EventTitle`]: undefined })[key]), 'https://secure.meckdems.org/a/:EventTitle')), true, undefined);
  return 'abc'.trim()
}


  console.log(getObjectProperty(___arguments.context.getComponentDataStoreByUid('240595d9bb7952626a7c2ee1870b15e9'), 'name'));

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/LoginMainPage/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
    async function socialLogin(providerCode, fieldsMappings, scope, options, redirectToPage, callbackUrlDomain) {
  if (typeof BackendlessUI !== 'undefined' && await BackendlessUI.DeviceAPI.isRunningEnv('NATIVE_SHELL')) {
    const user = await BackendlessUI.DeviceAPI.socialLogin(providerCode, fieldsMappings, scope, options, callbackUrlDomain)

    Backendless.UserService.setCurrentUser(user, true)

    if (redirectToPage) {
      BackendlessUI.Navigator.goToPage(redirectToPage)
    }
  } else {
    const redirectAfterLoginUrl = redirectToPage
      ? window.location.href.split('?')[0] + `?page=${ redirectToPage }`
      : window.location.href

    window.location = await Backendless.UserService.getAuthorizationUrlLink(providerCode, fieldsMappings, scope, false, redirectAfterLoginUrl, callbackUrlDomain)
  }}


  await socialLogin('googleplus', null, null, null, 'SelectApp', null);

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/SelectApp/components/4c84060f00d924acb19a14c487daadfc/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('CreateNametags', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/SelectApp/components/b709932bb5a3b1a9f70279f50fc6a446/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('CheckIn', undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/objectUpdate/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onEnter */
  async ['onEnter'](___arguments) {
      (function (componentUid, listItems) { ___arguments.context.getComponentByUid(componentUid).dynamicListItems = listItems })('a16246712c1711e51d55ba9a7ff9a538', (await Backendless.Data.of('Product').find(Backendless.DataQueryBuilder.create().setPageSize(100))));

  },
  /* handler:onEnter */
  /* content */
}))

define('./pages/objectUpdate/components/bc1322a4ce595af4bbbd3f3b192ec959/bundle.js', [], () => ({
  /* content */
  /* handler:onMouseOver */
  ['onMouseOver'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('bc1322a4ce595af4bbbd3f3b192ec959'))['background-color'] = '#cccccc';

  },
  /* handler:onMouseOver */
  /* handler:onMouseOut */
  ['onMouseOut'](___arguments) {
      ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('bc1322a4ce595af4bbbd3f3b192ec959'))['background-color'] = 'white';

  },
  /* handler:onMouseOut */
  /* handler:onClick */
  ['onClick'](___arguments) {
      ___arguments.context.pageData['selectedProduct'] = ___arguments.context.dataModel;

  },
  /* handler:onClick */
  /* handler:onStyleAssignment */
  ['onStyleAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'selectedProduct')) == ___arguments.context.dataModel) {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('bc1322a4ce595af4bbbd3f3b192ec959'))['border'] = 'solid 1px #CCCCCC';
  } else {
    ((function (componentUid) { return ___arguments.context.getComponentStyleByUid(componentUid) })('bc1322a4ce595af4bbbd3f3b192ec959'))['border'] = 'none';
  }

  },
  /* handler:onStyleAssignment */
  /* content */
}))

define('./pages/objectUpdate/components/15bd8fe8e0239b3dd5bfb49627a7cd12/bundle.js', [], () => ({
  /* content */
  /* handler:onValueAssignment */
  ['onValueAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  console.log(___arguments.context.getComponentDataStoreByUid('b0df1873c9075564d6caf02f0a822fb7'));

  return (getObjectProperty(___arguments.context.getComponentDataStoreByUid('b0df1873c9075564d6caf02f0a822fb7'), 'category'))

  },
  /* handler:onValueAssignment */
  /* content */
}))

define('./pages/objectUpdate/components/b0df1873c9075564d6caf02f0a822fb7/bundle.js', [], () => ({
  /* content */
  /* handler:onSubmit */
  async ['onSubmit'](___arguments) {
      await Backendless.Data.of('Product').save(___arguments.context.dataModel, true);
  ___arguments.context.pageData['selectedProduct'] = null;

  },
  /* handler:onSubmit */
  /* content */
}))

define('./layouts/Name_Tag_App_Header/components/f242079c9dd59247191e1bd14dacd184/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'Name'))

  },
  /* handler:onContentAssignment */
  /* content */
}))

